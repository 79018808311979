















































































































































  import { Component, Vue } from "vue-property-decorator";
  import {
    getOneMonthAppointmentData,
    updateBookingDetailsByDay,
    updateBookingOpenStatusByDay,
    updateDefaultSeatNum,
  } from "@/remote/seat";
  import { getDefaultStoreInfo } from "@/remote/common";
  import { UserModule } from "@/store/modules/user";
  import moment from "moment";
  import { IDayBookingModel ,ITimeQuantumBookingModel} from "./data/seatModel";
  import variables from "@/assets/css/element-variables.scss";
  import CustomTable from "@/components/CustomTable.vue";
  import { Form as ElForm } from "element-ui";

  // 装饰器模式
  @Component({
    components: {
      CustomTable,
    },
  })
  export default class extends Vue {
    // Data
    loading: boolean = false;
    minDate: string = "2020-01-01";
    maxDate: string = moment(new Date()).add(1, "y").format("YYYY-MM-DD");
    disabledDates = {
      start: this.minDate,
      end: moment(new Date()).format("YYYY-MM-DD"),
    };
    calendar: any = {}; // 日历对象

    currentDate: string = ""; // YYYY-MM-DD格式
    curTimeSlotId: string = "";
    currentBookingModel: IDayBookingModel = {};
    currentBookingModelIndex: number = -1;
    curTimeQuantumIndex: number = 0; // 当前选中的时间段
    dialogTableVisible: boolean = false;

    nowDate: string = moment(new Date()).format("YYYY-MM-DD");
    defaultSeatNum: number = 30;
    list: IDayBookingModel[] = [];

    editVisible: boolean = false;
    confirmBtnIsLoading: boolean = false; // 默认席位的确认按钮
    saveBtnIsLoading: boolean = false; // 右侧保存按钮

    curPageKey = ""; // YYYY-MM

    // Computed
    get timeForShow() {
      if (this.currentDate.length === 0) return "";
      moment.locale("zh-cn");
      return moment(this.currentDate).format("YYYY年MM月DD日，dddd");
    }

    get variables() {
      return variables;
    }

    // Methods
    mounted() {
      this._fetchBarInfo();
      this.$nextTick(() => {
        this.calendar = this.$refs["calendar"];
        if (this.calendar && this.calendar.pages) {
          const page = this.calendar.pages[0];
          this.curPageKey = page.key;
          this._fetchOneMonthAppointmentData(page.key);
        }
      });
      UserModule.addBarChangeCallBack(() => {
        this._fetchBarInfo();
        const page = this.calendar.pages[0];
        this.curPageKey = page.key;
        this._fetchOneMonthAppointmentData(page.key);
        this.editVisible = false;
      });
    }

    // 根据日期来获取Day lable的不同的class name
    _getDayClassName(date: string) {
      date = moment(date).format("YYYY-MM-DD");
      if (date === this.currentDate && date === this.nowDate) {
        return "day-is-selected day-is-today";
      } else if (date === this.currentDate) {
        return "day-is-selected";
      } else if (date === this.nowDate) {
        return "day-is-today";
      }
      return "";
    }

    // 根据日期来获取day Item不同的class name
    _getRowClassName(date: string) {
      const newDate = moment(date);
      const disableMaxDate = moment(this.disabledDates.end);
      const unclickableDate = moment(this.nowDate).add(6, "M");
      if (newDate.format("YYYY-MM-DD") === this.currentDate) {
        return "is-selected";
      } else if (newDate.isSameOrBefore(disableMaxDate)) {
        return "is-disabled";
      } else if (newDate.isAfter(unclickableDate)) {
        // 今天以后半年的数据不可点击进行编辑
        return "is-unclickable";
      }
    }

    _handleSharpCornerPosition(index: number) {
      if (index === 0) {
        return "8%";
      } else if (index === 1) {
        return "34%";
      } else if (index === 2) {
        return "60%";
      } else if (index === 3) {
        return "86%";
      }
    }

    _onClickDate(day: any) {
      this.editVisible = true;
      this.curTimeQuantumIndex = 0;
      this.currentDate = moment(day.date).format("YYYY-MM-DD");
      const res = this._getMatchDayBookingModel(this.currentDate);
      if (res) {
        this.currentBookingModel = JSON.parse(JSON.stringify(res.model));
        console.log( this.currentBookingModel)
        this.currentBookingModelIndex = res.index;
        if (this.currentBookingModel) {
          this.$nextTick(() => {
            const form = this.$refs["seatForm"] as ElForm;
          });
        }
      } else {
        this.currentBookingModel = {};
        this.currentBookingModelIndex = -1;
      }
    }

    _onUpdatePage() {
      if (this.calendar && this.calendar.pages) {
        const page = this.calendar.pages[0];
        this.curPageKey = page.key;
        this._fetchOneMonthAppointmentData(page.key);
      }
    }

    _onClickTimeQuant(index: number) {
      this.curTimeQuantumIndex = index;
    }

    // 确认修改默认席位
    async _onClickUpdateSeatNum() {
      try {
        this.confirmBtnIsLoading = true;
        const res = await updateDefaultSeatNum(this.defaultSeatNum);
        this.confirmBtnIsLoading = false;
        if (res && res.status === 200) {
          this.$message.success("修改成功!");
          this._fetchOneMonthAppointmentData(this.curPageKey);
        }
      } catch {
        this.confirmBtnIsLoading = false;
      }
    }


    // 查看顾客列表
    _onClickCheckCustomList() {
      console.log(this.currentBookingModel)
      if (!this.currentBookingModel) return;
      this.currentDate=this.currentBookingModel.inventories![this.curTimeQuantumIndex].date
      this.curTimeSlotId = this.currentBookingModel.inventories![this.curTimeQuantumIndex].timeSlotId;
      this.dialogTableVisible = true;
    }

    // 保存修改
    _onClickSave() {
      if (!this.currentBookingModel) return;
      
      this._uploadData();
       
    }
async _updateOpenState(){
  console.log(this.currentBookingModel.isAvailable)
   this.$confirm("", this.currentBookingModel.isAvailable ? "确认关闭预约吗？" : "确认开放预约吗？", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      center: true,
    })
      .then(async() => {
        console.log("确定")
        const res = await updateBookingOpenStatusByDay(
          this.currentBookingModel.date || "",
          !this.currentBookingModel.isAvailable || false
        );
        console.log(res)
        if(res.status === 200){
            this.currentBookingModel.isAvailable=!this.currentBookingModel.isAvailable
            this._fetchOneMonthAppointmentData(this.curPageKey);
        }
       
        // this.currentBookingModel=this.currentBookingModel
      })
      .catch(() => {
        console.log("取消")
        // this.currentBookingModel=this.currentBookingModel
      });
}
    async _uploadData() {
      if (!this.currentBookingModel) return;
      try {
        this.saveBtnIsLoading = true;
        console.log("_uploadData")
        console.log(this.currentBookingModel)
        const res = await updateBookingDetailsByDay(
          this.currentBookingModel.date || "",
          this.currentBookingModel.inventories![this.curTimeQuantumIndex],
          this.currentBookingModel.isAvailable || false
        );
        this.saveBtnIsLoading = false;
        if (res.status === 200) {
          this.$message.success("保存成功!");
          this.list[this.currentBookingModelIndex] = this.currentBookingModel;
          this._fetchOneMonthAppointmentData(this.curPageKey);
        }
      } catch {
        this.saveBtnIsLoading = false;
      }
    }

    // 获取某个月的预约情况 date: YYYY-MM
    async _fetchOneMonthAppointmentData(date: string) {
      const arr: string[] = date.split("-");
      if (arr.length !== 2) return;
      const year: string = arr[0];
      const month: string = parseInt(arr[1]) < 10 ? `0${arr[1]}` : arr[1];
      this.loading = true;
      try {
        const res = await getOneMonthAppointmentData(`${year}${month}`);
        if (res && res.data && res.data.data) {
          res.data.data.forEach((element :IDayBookingModel)=> {
            if(element.inventories ){
              element.inventories.forEach((timeSlot:ITimeQuantumBookingModel)=>{
timeSlot.bookingNumber=timeSlot.originalQuantity-timeSlot.quantity;
              })
            }
          });
          this.list = res.data.data;
        } else {
          this.list = [];
        }
        this.loading = false;
      } catch {
        this.loading = false;
        this.list = [];
        // console.log("getOneMonthAppointmentData出错了");
      }
    }

    async _fetchBarInfo() {
      try {
        const res = await getDefaultStoreInfo();
        if (res && res.data && res.data.data) {
          const {
            defaultQuantity
          } = res.data.data;
          this.defaultSeatNum = defaultQuantity; 
          console.log(this.defaultSeatNum)
        }
      } catch {
        // console.log("getWineryInfo 出错了");
      }
    }

    _getMatchDayBookingModel(date: string): {
      model: IDayBookingModel;
      index: number;
    } | null {
      if (this.list.length === 0) return null;
      const index = this.list.findIndex((element: IDayBookingModel) => {
        return element.date === date;
      });
      if (index === -1) return null;
      return {
        model: this.list[index],
        index,
      };
    }

    _handleBookingData(date: string, type: string): string {
      date = moment(date).format("YYYY-MM-DD");
      const res = this._getMatchDayBookingModel(date);
      if (!res) return "";
      const { model } = res;

      if (type === "class") {
        return model.isAvailable ? "" : "is-disabled";
      }

      var str: string = "";
      const inventories = model.inventories || [];
      inventories.forEach((element, index) => {
        str = str + element.bookingNumber + "/" + element.originalQuantity;
        if (index != inventories.length - 1) {
          str = str + "<br>";
        }
      });
      // const str = `${model.slots[0].bookingNumber}/${model.slots[0].seatNumber}<br>${model.slots[1].bookingNumber}/${model.slots[1].seatNumber}<br>${model.slots[2].bookingNumber}/${model.slots[2].seatNumber}`
      return str;
    }

    _handleTimeQuantumForshowFrom(from: string) {
      return moment(from, "HH:mm:ss").format("HH:mm");
    }

    _handleTimeQuantumForshowTo(to: string) {
      return moment(to, "HH:mm:ss").format("HH:mm");
    }

    _onNameInput(value: string) {
      var temp = 0;
      const maxLen = 12;
      for (var i = 0; i < value.length; i++) {
        if (/[\u4e00-\u9fa5]/.test(value[i])) {
          temp += 2;
        } else {
          temp++;
        }
        if (temp > maxLen) {
          // @ts-ignore
          value = value.substr(0, i).trimStart();
          // }
        }
      }
    }
  }
