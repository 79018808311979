import http from './interface'
import { ITimeQuantumBookingModel } from '@/pages/seat/data/seatModel'
import { UserModule } from '@/store/modules/user'

// 得到某一个月的日历信息
export const getOneMonthAppointmentData = (date: string) => {
  return http.get(
    `/api/v3/wineries/${UserModule.wineryId}/products/tickets/inventories?month=${date}`
  )
}

// 修改默认坐席数量
export const updateDefaultSeatNum = (quantity: number) => {
  return http.patch(`/api/v3/wineries/${UserModule.wineryId}/winery-product-inventories/default-setting`, { quantity,productType:'ticket' })
}

export const updateBarDepoitRule = (isNeedDepositIfExceedLimit: boolean) => {
  return http.patch(`/api/v3/wineries/${UserModule.wineryId}/deposit`, {
    isNeedDepositIfExceedLimit
  })
}

// 更新某一天的预约详情
export const updateBookingDetailsByDay = (
  dates: string,
  inventories: ITimeQuantumBookingModel,
  isAvailable: boolean
  // isSpecialDay: boolean,
  // specialDayName: string

) => {
  return http.patch(
    `/api/v3/wineries/${UserModule.wineryId}/winery-product-inventories`,
    {
     date:dates.replace(/-/g, ''),
     productType:'ticket',
     timeSlotId:inventories.timeSlot.id,
     quantity:inventories.originalQuantity,
    //  isAvailable
    }
  )
}

// 更新某一天的预约详情
export const updateBookingOpenStatusByDay = (
  dates: string,
  isAvailable: boolean
  // isSpecialDay: boolean,
  // specialDayName: string

) => {
  return http.patch(
    `/api/v3/wineries/${UserModule.wineryId}/winery-product-inventories`,
    {
     date:dates.replace(/-/g, ''),
     productType:'ticket',
     isAvailable
    }
  )
}
