var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-row',{staticClass:"seat-page",attrs:{"type":"flex"}},[_c('el-col',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"seat-page-left",attrs:{"span":15}},[_c('div',[_c('span',{staticClass:"seat-page-left-prompt"},[_vm._v("默认开放座席数量")]),_c('el-input-number',{attrs:{"min":1,"max":1000},model:{value:(_vm.defaultSeatNum),callback:function ($$v) {_vm.defaultSeatNum=$$v},expression:"defaultSeatNum"}}),_c('el-button',{staticStyle:{"margin-left":"15px"},attrs:{"type":"primary","size":"small","loading":_vm.confirmBtnIsLoading},on:{"click":_vm._onClickUpdateSeatNum}},[_vm._v("确认修改")])],1),_c('div',{staticClass:"seat-page-left-title"},[_vm._v("座席设置与预约情况概览")]),_c('div',{staticStyle:{"width":"600px"}},[_c('vc-calendar',{ref:"calendar",staticStyle:{"border":"none"},attrs:{"is-expanded":"","min-date":_vm.minDate,"max-date":_vm.maxDate},on:{"transition-end":_vm._onUpdatePage},scopedSlots:_vm._u([{key:"day-content",fn:function(ref){
var day = ref.day;
return [_c('div',{staticClass:"content-container",class:_vm._getRowClassName(day.date),on:{"click":function($event){return _vm._onClickDate(day)}}},[_c('div',{staticClass:"day-item",class:_vm._getDayClassName(day.date)},[_vm._v(" "+_vm._s(day.day)+" ")]),_c('div',{staticClass:"booking-item",class:_vm._handleBookingData(day.date, 'class'),domProps:{"innerHTML":_vm._s(_vm._handleBookingData(day.date, 'seats'))}})])]}}])})],1)]),(_vm.editVisible)?_c('el-col',{attrs:{"span":9}},[_c('el-form',{ref:"seatForm",staticClass:"seat-page-right",attrs:{"model":_vm.currentBookingModel}},[_c('div',{staticClass:"seat-page-right-date"},[_vm._v(_vm._s(_vm.timeForShow))]),_c('div',{staticClass:"seat-page-right-header"},[_c('div',{staticClass:"seat-page-right-header-open"},[_c('span',{staticClass:"prompt"},[_vm._v(" 是否开放预约? ")]),_c('el-switch',{staticStyle:{"margin-right":"10px"},attrs:{"value":_vm.currentBookingModel.isAvailable,"active-color":_vm.variables.theme},on:{"change":_vm._updateOpenState}}),_c('span',[_vm._v(_vm._s(_vm.currentBookingModel.isAvailable ? "开放" : "不开放"))])],1)]),_c('el-row',{staticStyle:{"margin":"30px 0 15px 0"}},_vm._l((_vm.currentBookingModel.inventories),function(item,index){return _c('el-col',{key:index,staticClass:"time-quant-container",class:index === _vm.curTimeQuantumIndex ? 'selected' : '',attrs:{"span":5},nativeOn:{"click":function($event){return _vm._onClickTimeQuant(index)}}},[_c('div',{staticClass:"time-quant-container-time"},[_vm._v(" "+_vm._s(_vm._handleTimeQuantumForshowFrom(item.timeSlot.startAt))+" ")])])}),1),_vm._l((_vm.currentBookingModel.inventories),function(item,index){return _c('div',{key:index},[(_vm.curTimeQuantumIndex === index)?_c('div',{staticClass:"number-container"},[_c('div',{staticClass:"left-sharp-corner",style:({
                left: _vm._handleSharpCornerPosition(_vm.curTimeQuantumIndex),
              })}),_c('span',[_vm._v("开放座席数量")]),_c('el-input-number',{staticStyle:{"margin-left":"15px"},attrs:{"disabled":!_vm.currentBookingModel.isAvailable ||
                new Date() >
                  new Date(_vm.currentBookingModel.date + ' ' + item.from),"min":0,"max":1000},model:{value:(item.originalQuantity),callback:function ($$v) {_vm.$set(item, "originalQuantity", $$v)},expression:"item.originalQuantity"}})],1):_vm._e()])}),_c('div',{staticStyle:{"margin-top":"20px"}},[_vm._v(" 当前时段预约人数: "+_vm._s(_vm.currentBookingModel.inventories[_vm.curTimeQuantumIndex].bookingNumber)+"位 "),_c('el-button',{staticStyle:{"margin-left":"5px"},attrs:{"plain":"","size":"samll","disabled":_vm.currentBookingModel.inventories &&
              _vm.currentBookingModel.inventories[_vm.curTimeQuantumIndex].bookingNumber ===
                0},on:{"click":_vm._onClickCheckCustomList}},[_vm._v("查看预约顾客列表")])],1),_c('el-button',{staticStyle:{"width":"100%","margin-top":"20px"},attrs:{"type":"primary","loading":_vm.saveBtnIsLoading,"disabled":!_vm.currentBookingModel.isAvailable},on:{"click":_vm._onClickSave}},[_vm._v("保存")])],2)],1):_vm._e()],1),_c('custom-table',{attrs:{"dialogTableVisible":_vm.dialogTableVisible,"timeSlotId":_vm.curTimeSlotId,"date":_vm.currentDate,"type":"daily"},on:{"update:dialogTableVisible":function($event){_vm.dialogTableVisible=$event},"update:dialog-table-visible":function($event){_vm.dialogTableVisible=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }