

































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  // IBookingCustomerBasicInfo,
  IEventCustomerBasicInfo,
} from "@/data/commonModel";
import {
  getEventBookingCustomList,
  updateEventGuestArrivelStatus,
  exportEventCustomList,
} from "@/remote/event";
import { getCustomListByDay } from "@/remote/common";
import {
  IBookingModel,
  IProductItems,
} from "@/pages/booking/data/bookingModel";

// 装饰器模式
@Component({
  components: {},
})
export default class extends Vue {
  // Props
  // 显示或隐藏
  @Prop({ default: false })
  dialogTableVisible!: boolean;
  // 标题
  @Prop({ default: "预约名单" })
  title!: string;
  // 类型：event/daily
  @Prop({ default: "event" })
  type!: string;
  // type=event时需要活动ID
  @Prop() eventId!: string;
  // type=daily时需要日期
  @Prop() timeSlotId!: string;
  @Prop() date!: string;

  // Data
  customList: IBookingModel[] = [];
  listLoading: boolean = false;
  searchText: string = "";
  tempList: IBookingModel[] = [];
  exportButtonLoading: boolean = false;

  // Watch
  // @Watch("eventId", { immediate: true })
  // onEventIdChange(value: string) {
  //   if (value && value.length > 0) this._fetchEventCustomList(value);
  // }

  @Watch("dialogTableVisible", { immediate: true, deep: true })
  onTimeVisibleChange() {
    if (this.dialogTableVisible && this.type === "daily")
      this._fetchCustomListByDay();
  }

  // @Watch("searchText", { immediate: true })
  // onSearchChange(value: string) {
  //   // console.log("onSearchChange: " + value);
  //   if (value.length === 0) {
  //     this.customList = this.tempList;
  //     return;
  //   }
  //   var nameReg = new RegExp(`^.*[${value}].*$`);
  //   var phoneNumberReg = new RegExp(`${value}$`);
  //   this.customList = this.tempList.filter(
  //     (element: IEventCustomerBasicInfo) => {
  //       return (
  //         nameReg.test(element.name) || phoneNumberReg.test(element.phoneNumber)
  //       );
  //     }
  //   );
  // }

  // Methods
  _beforeModalClose() {
    this.$emit("update:dialogTableVisible", false); // 直接修改父组件的属性
  }

  // 获取某场活动的顾客列表
  async _fetchEventCustomList(eventId: string) {
    try {
      this.listLoading = true;
      const res = await getEventBookingCustomList(eventId);
      if (res && res.data && res.data.data) {
        const { data } = res.data;

        console.log("data-----", data);

        this.customList = data;
        this.tempList = this.customList;
        this.listLoading = false;
      }
    } catch {
      this.listLoading = false;
      // console.log("getEventBookingCustomList出错了");
    }
  }

  // 获取日常预约的某天的顾客列表
  async _fetchCustomListByDay() {
    this.customList=[]
    try {
      const res = await getCustomListByDay(this.timeSlotId, this.date);
      console.log("_fetchCustomListByDay", res);
      if (res && res.data && res.data.data) {
        const { items } = res.data.data;
        console.log(items);
         
        items.forEach((element: IBookingModel) => {
          element.name = element.customerFirstName + element.customerLastName;
          element.timeSlotText =
            element.timeSlot.startAt + "-" + element.timeSlot.endAt;
            element.itemsNew = [];
          element.vouchers.forEach((product: IProductItems) => {
            //针对套餐进行分类
            const products = element.itemsNew.filter((item) => {
              return item.productId === product.productId;
            });
            const newBookingProductItem = products.length
              ? products[0]
              : {
                  productId: product.productId,
                  totalPrice: 0,
                  unitPrice: product.unitPrice,
                  totalQuantity: 0,
                  redeemedQuantity: 0,
                  refundedQuantity: 0,
                  refundingQuantity: 0,
                  expiredQuantity: 0,
                  validQuantity: 0,
                  pendingQuantity: 0,
                  title: product.title,
                  items: [],
                  quantity: 0,
                };
            newBookingProductItem.items.push(product);
            newBookingProductItem.totalQuantity++;
            if (product.isLocked) {
              newBookingProductItem.refundingQuantity++;
            }  
            else if (product.status === "valid" || (product.type === "customizable" &&
              product.status === "pending")) {
              newBookingProductItem.validQuantity++;
            } else if (product.status === "redeemed") {
              newBookingProductItem.redeemedQuantity++;
            } else if (product.status === "refunded") {
              newBookingProductItem.refundedQuantity++;
            } else if (product.status === "expired") {
              newBookingProductItem.expiredQuantity++;
            }
            if (!products.length) {
              element.itemsNew.push(newBookingProductItem);
            }
          });
        });
        this.customList = items;
        console.log("customList",this.customList);
        this.listLoading = false;
      }
    } catch {
      // console.log("getCustomListByDay 出错了");
    }
  }

  async _onClickExport() {
    this.exportButtonLoading = true;
    try {
      const res = await exportEventCustomList(this.eventId, this.searchText);
      if (!res || !res.data) return;
      let a = document.createElement("a");
      let blob = new Blob([res.data], {
        type: "application/octet-stream", //这里放文件类型，后台返回的response会有这个文件类型，放进来就行
      });
      a.href = URL.createObjectURL(blob);
      a.download = "活动参与者名单.xlsx";
      a.click();
      this.exportButtonLoading = false;
    } catch {
      this.exportButtonLoading = false;
      this.$message.error("导出失败，请重试");
      // console.log("exportCustomList 出错了");
    }
  }
}
