import http from './interface'
import { IEventListQuery } from '@/pages/event/data/eventModel'
import { IEventFormRequestModel } from '@/pages/event/data/eventModel'
import { UserModule } from '@/store/modules/user'

// 创建活动
export const createEvent = (data: IEventFormRequestModel) => {
  return http.post(`/api/v3/wineries/${UserModule.wineryId}/events`, data)
}

export const getEventRecords = (data: IEventListQuery) => {
  const url = `/api/v3/wineries/${UserModule.wineryId}/events?type=${data.type}&page=${data.page}&size=${data.size}&title=${data.searchText}&order=${data.order}`
  return http.get(url)
}

// 更新活动
export const updateEvent = (evnetId: string, data: IEventFormRequestModel) => {
  return http.put(`/api/v3/wineries/${UserModule.wineryId}/events/${evnetId}`, data)
}

// 删除
export const deleteEvent = (eventId: string) => {
  return http.delete(`/api/v3/wineries/${UserModule.wineryId}/events/${eventId}`)
}

// 置顶/取消置顶
export const updateEventSticktatus = (eventId: string, isTop: boolean) => {
  return http.patch(`/api/v3/wineries/${UserModule.wineryId}/events/${eventId}/top`, {
    isTop,
  })
}

// 取消发布和发布
export const updateEventPublishStatus = (
  eventId: string,
  isPublished: boolean
) => {
  return http.patch(
    `/api/v3/wineries/${UserModule.wineryId}/events/${eventId}/publish`,
    {
      isPublished,
    }
  )
}

// 获取某一天的预约数量/可预约数量
export const getBookingNumberByDate = (date: string) => {
  const url = `/api/v3/wineries/${UserModule.wineryId}/bookings/date?date=${date}`
  return http.get(url)
}

// 获取某个活动的预约名单
export const getEventBookingCustomList = (eventId: string) => {
  const url = `/api/v3/wineries/${UserModule.wineryId}/bookings/events/${eventId}/users`
  return http.get(url)
}

// 获取活动详情
export const getEventDetails = (eventId: string) => {
  return http.get(`/api/v3/wineries/${UserModule.wineryId}/events/${eventId}`)
}

export const updateEventGuestArrivelStatus = (
  eventBookingId: string,
  isArrived: boolean
) => {
  return http.patch(
    `/api/v3/wineries/${UserModule.wineryId}/bookings/eventBookings/${eventBookingId}/arrival`,
    { isArrived }
  )
}

// 导出活动参与者名单
export const exportEventCustomList = (eventId: string, searchText: string) => {
  return http.get(
    `/api/v3/wineries/${UserModule.wineryId}/bookings/events/${eventId}/users/export?nameOrPhone=${searchText}`,
    {
      responseType: 'blob', //告诉服务器我们需要的响应格式
    }
  )
}
